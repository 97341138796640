import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";

import "./certification@gen.scss";

const GeneratedCertification = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          Digital
          StacksはPagerDutyの公式認定パートナーです｜PagerDuty正規販売代理店（株）Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="Digital Stacksは、PagerDutyの公式認定パートナー資格を取得しています。PagerDutyの導入は、よりきめ細かいサポートを提供できるDigital Stacksにおまかせください。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="Digital Stacksは、PagerDutyの公式認定パートナー資格を取得しています。PagerDutyの導入は、よりきめ細かいサポートを提供できるDigital Stacksにおまかせください。"
        />

        <meta
          property="og:title"
          content="Digital StacksはPagerDutyの公式認定パートナーです｜PagerDuty正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="Digital Stacksは、PagerDutyの公式認定パートナー資格を取得しています。PagerDutyの導入は、よりきめ細かいサポートを提供できるDigital Stacksにおまかせください。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/certification/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/certification/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <div className="certificationWrapper">
        <div className="certification-container">
          <div className="certification-banner">
            <img
              src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Certification/Group_25_58ae3aebc9.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672990090675&tr=q-100,f-webp"
              loading="lazy"
              alt="Certification Page Banner"
              className="banner-bg"
            />
            <h1 className="banner-title">
              Digital StacksはPagerDutyの公式認定パートナーです
            </h1>
          </div>
          <p className="certification-summary">
            日本初のPagerDuty正規販売代理店として、私たちはPagerDutyの活用に関して常にベストを尽くしてお客様をサポートしています。既にPagerDuty
            Foundational Partner
            Certificationを取得した有資格のエキスパートが在籍し、お客様によりきめ細かなサポートを提供しております。PagerDutyはDigital
            Stacksに安心しておまかせください。
          </p>
          <div className="px-[25px]">
            <img
              src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Certification/Pagerduty_Certification_1_aa87e9bf21.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672990090721&tr=w-480,q-100,f-webp"
              alt="Pagerduty Certificate badge"
              className="certificate-badge"
              loading="lazy"
            />
          </div>
          <div className="certificate-fancy-container">
            <div className="certificate-fancy-section-2 bg-[#4EB346] text-white">
              <div className="text-center text-white">
                <h2 className="font-normal text-[28px] leading-[44px]">
                  Digital Stacksが選ばれる理由
                </h2>
              </div>
              <div className="certificate-fancy-section certificate-fancy-section-2">
                <div className="certificate-fancy-item">
                  <div className="certificate-fancy-item-img">
                    <img
                      src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Group_6_e254358c28.png?updated_at=2022-09-20T18:42:36.508Z"
                      alt="運用状況をレ ポート機能で可視化"
                    />
                  </div>
                  <div className="certificate-fancy-item-desc">
                    日本語での一次サポートを受けられます。
                  </div>
                </div>
                <div className="certificate-fancy-item">
                  <div className="certificate-fancy-item-img">
                    <img
                      src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Group_5_04caa77e62.png?updated_at=2022-09-20T18:42:37.053Z"
                      alt="ビジュアル化された コンソール"
                    />
                  </div>
                  <div className="certificate-fancy-item-desc">
                    お支払いは日本円で、年1回の請求書払いで承ります。
                  </div>
                </div>
                <div className="certificate-fancy-item">
                  <div className="certificate-fancy-item-img">
                    <img
                      src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Group_7_a530662e0c.png?updated_at=2022-09-20T18:42:36.386Z"
                      alt="どこにいても安心​"
                    />
                  </div>
                  <div className="certificate-fancy-item-desc">
                    チームの増員に合わせて、ユーザー数を柔軟に追加できます。
                  </div>
                </div>
              </div>
              <a
                href="https://pagerduty.digitalstacks.net/merit/"
                className="flex justify-center items-center w-full group"
              >
                <span className="text-[18px] text-white underline leading-[24.52px] group-hover:text-[#353535] transition-all duration-300 ease-in-out">
                  さらに詳しく ​
                </span>
                <div className="w-[34px] h-[34px] bg-[#353535] flex justify-center items-center rounded-full">
                  <svg
                    width={21}
                    height={16}
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.7071 8.70711C21.0976 8.31661 21.0976 7.68341 20.7071 7.29291L14.3431 0.928937C13.9526 0.538417 13.3195 0.538417 12.9289 0.928937C12.5384 1.31951 12.5384 1.95261 12.9289 2.34311L18.5858 8.00001L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.70711ZM0 9.00001H20V7.00001H0V9.00001Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </a>
              <div className="certificate-fancy-triangle -top-[1%] left-[50%] -translate-x-[50%] h-[64px]">
                <img
                  className="h-[32px]"
                  src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/triangle_White_a6049dde2c.png"
                  alt="certificate-fancy triangle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedCertification;
